import React from "react";
import CommonForm from "../components/common/CommonForm";
import digitalHealthSubscription from "../assets/digital-health-subscription/digitalHealthSubscription.png";
import digitalHealthSubscriptionTab from "../assets/digital-health-subscription/digitalHealthSubscriptionTab.png";
import digitalHealthSubscriptionMobile from "../assets/digital-health-subscription/digitalHealthSubscriptionMobile.png";
import doctorConsulatation from "../assets/digital-health-subscription/doctor-consulatation.svg";
import hospitalCashBenefit from "../assets/digital-health-subscription/hospital-cash-benefit.svg";
import discountLabTest from "../assets/digital-health-subscription/discount-lab-test.svg";
import digitalHealthSubscriptionIcon from "../assets/digital-health-subscription/digital-health-subscription.svg";

const DigitalHealthSubscription = () => {
  const getData = [
    {
      title: "Doctor consults",
      description: "you can consult a doctor as many times as you want.",
      image: doctorConsulatation,
    },
    {
      title: "Hospital cash benefit",
      description:
        "₹5000/-per day in case of hospitalization and ₹10,000/- per day in case of ICU hospitalization.",
      image: hospitalCashBenefit,
    },
    {
      title: "Discount on diagnostic tests & medicines",
      description: "order via our app and get discounts upto 25%.",
      image: discountLabTest,
    },
    {
      title: "Digital health subscription",
      description:
        "store and track your daily vitals, symptoms, exercise, medications, diet and more with our aiqahealth mobile application.",
      image: digitalHealthSubscriptionIcon,
    },
  ];

  return (
    <div className="home-wrapper flex justify-center">
      <div className="w-full md:max-w-[1248px] mb-[48px] lg:my-[120px]">
        <div className="bgc-transparent know-more-plan-wrapper lg:p-10 rounded-[40px]">
          <div className="hidden lg:block">
            <div className="grid grid-cols-2 gap-14 mb-20">
              <div className="flex flex-col justify-center px-5">
                <div className="heading1 mb-6">
                  Digital health subscription combo
                </div>
                <div className="flex items-center mb-10">
                  <span className="heading2 pr-2">₹9,998/-</span>
                  <span className="para line-through">12,999</span>
                </div>
                <div className="bg-[#FBE7E8] px-3 py-[6px] rounded-xl w-52">
                  plan validity: 3 months
                </div>
              </div>

              <div className="relative">
                <img
                  className="rounded-3xl"
                  src={digitalHealthSubscription}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="hidden md:block lg:hidden xl:hidden 2xl:hidden">
            <div className="grid gap-6 md:gap-10 mb-20">
              <div className="relative">
                <div className="validity-text heading3 font-[400]">
                  plan validity: 3 months
                </div>
                <img
                  src={digitalHealthSubscriptionTab}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="px-5">
                <div className="heading1 mb-3">
                  Digital health subscription combo
                </div>
                <div>
                  <span className="heading2 pr-2">₹9,998/-</span>
                  <span className="para line-through">12,999</span>
                </div>
              </div>
            </div>
          </div>

          <div className="md:hidden">
            <div className="grid grid-cols-1 gap-6 md:gap-10">
              <div className="relative">
                <div className="validity-text para font-[400]">
                  plan validity: 3 months
                </div>
                <img
                  src={digitalHealthSubscriptionMobile}
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="px-5 md:mb-0 mb-10">
                <div className="mb-3">
                  <div className="heading1 mb-3">
                    Digital health subscription combo
                  </div>
                  <div>
                    <span className="heading2 pr-2">₹9,998/-</span>
                    <span className="para line-through">12,999</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mx-5">
            {getData.map(({ image, title, description }) => (
              <div className="know-more-plan-card rounded-lg text-center p-5 md:py-5 md:px-3">
                <div className="flex justify-center mb-6">
                  <img src={image} alt="" className="h-20 w-20" />
                </div>
                <div>
                  <h2 className="heading3 mb-2 know-more-subTitle">{title}</h2>
                  <p className="para leading-6 font-[400]">{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="know-more-plan-wrapper plan-wrapper py-10 px-5 md:p-10 md:rounded-[40px] mt-4 md:mt-[120px]">
          <p className="heading2 text-center md:text-left mb-2">
            Get a call back
          </p>
          <p className="para text-center md:text-left">
            Know more about digital health subscription combo?
          </p>
          <CommonForm
            subject="digital health subscription"
            title="Book a consultation"
          />
        </div>
      </div>
    </div>
  );
};

export default DigitalHealthSubscription;
