import React from "react";
import insta from "../assets/common/instaLogo.svg";
import facebook from "../assets/common/facebookLogo.svg";
import linkedin from "../assets/common/linkedinLogo.svg";
import { NavLink } from "react-router-dom";
import CustomNavLink from "./common/CustomNavLink";
import phone from "../assets/contact_us/carbon_phone-filled-red.svg";
import mail from "../assets/contact_us/fluent_mail-20-filled-red.svg";
import AiqaLogo from "../assets/common/aiqahealthWhiteLogo.svg";

const Footer = () => {
  const data = [
    {
      img: insta,
      link: "https://www.instagram.com/aiqahealth/profilecard/?igsh=Y3J1aHd6bHR3bXZs",
    },
    { img: facebook, link: "https://fb.watch/aAJb6ruaIo/" },
    {
      img: linkedin,
      link: "https://www.linkedin.com/posts/doctoroncallindia_commoncold-cough-covidguidelines-activity-6888345117518913536-Js9h",
    },
  ];
  return (
    <div className="bg-primary-500 text-white flex justify-center">
      <div className="w-full md:max-w-[1248px] footer-wrapper px-5 lg:p-0">
        <div className="flex justify-between gap-4 my-5 md:my-[34.5px] items-center">
          <CustomNavLink exact={true} to="/">
            <img src={AiqaLogo} alt="" />
          </CustomNavLink>
          <div className="flex gap-5 ">
            {data.map((item, index) => {
              return (
                <a
                  key={index}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    key={index}
                    className="bg-primary-400 h-8 w-8 md:h-12 md:w-12 flex justify-center items-center rounded-full"
                  >
                    {" "}
                    <img
                      style={{ width: "13px", height: "16px" }}
                      alt=""
                      src={item.img}
                    />
                  </div>
                </a>
              );
            })}
          </div>
        </div>
        <hr className="w-full mb-10" />
        <div className="flex flex-wrap justify-between mb-6">
          <div className="w-full md:w-[45%] pl-0">
            <div className="w-full lg:w-96">
              <p className="para text-white leading-6">
                Hospido private limited, 5th floor, SAS tower, sector 38,
                Gurugram, Haryana, 122001
              </p>
            </div>
            <div className="mt-5">
              <div className="flex items-center">
                <span className="bg-white rounded-full w-8 h-8 mr-3 p-2">
                  <img src={phone} alt="" />
                </span>
                <a href="tel:9105556666">+91 6262 306 306</a>
              </div>
              <div className="flex items-center mt-5">
                <span className="bg-white rounded-full w-8 h-8 mr-3 p-2">
                  <img src={mail} alt="" />
                </span>
                <a href="mailto: care@aiqahealth.com">care@aiqahealth.com</a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[55%] gap-2 flex flex-col md:flex-row justify-between  mt-5 md:mt-0 para text-white">
            <div className="flex gap-y-3 flex-col">
              <NavLink to="/how-to-reach">How to reach</NavLink>
              <NavLink to="/privacy-policy">Privacy policy</NavLink>
              <NavLink to="/terms-and-conditions">Terms & conditions</NavLink>
            </div>
            <div className="flex gap-y-3 flex-col">
              <NavLink to="/grievances">Customer grievances policy</NavLink>
              <NavLink to="/investor-portal">Investor portal</NavLink>
            </div>
          </div>
        </div>
        <div className="text-center pb-4 px-10 para text-white lg:block" hidden>
          Insurance products are offered and serviced by Hospido Private Limited
          | IRDAI Registration Code: CA0829, License category - Corporate Agent
          (Composite), Valid till 07-12-2025 | CIN: U85300DL2020PTC364444
        </div>
        <div className="text-center para mb-6 text-white">
          © aiqahealth 2024 - All Rights Reserved
        </div>
      </div>
    </div>
  );
};
export default Footer;
