import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import SwasthyaSuraksha from "../assets/homepage/swasthya-suraksha-Know-More.png";
import healthyWay from "../assets/homepage/healthyway-Know-More.png";
import healthEdge from "../assets/homepage/healthEdge-Know-More.png";
import SwasthyaSurakshaTab from "../assets/homepage/swasthya-suraksha-Know-More-Tab.png";
import healthyWayTab from "../assets/homepage/healthyway-Know-More-Tab.png";
import healthEdgeTab from "../assets/homepage/healthEdge-Know-More-Tab.png";
import SwasthyaSurakshaMobile from "../assets/homepage/swasthya-suraksha-Know-More-Mobile.png";
import healthyWayMobile from "../assets/homepage/healthyway-Know-More-Mobile.png";
import healthEdgeMobile from "../assets/homepage/healthEdge-Know-More-Mobile.png";
import doctorConsulatation from "../assets/homepage/doctor-consulatation.svg";
import cashBenefit from "../assets/homepage/cashBenefit.svg";
import accidentalInsurance from "../assets/homepage/accidentalInsurance.svg";
import nutritionistConsulatation from "../assets/homepage/nutritionist-consulatation.svg";
import diagnosticTest from "../assets/homepage/diagnostic-test.svg";
import fitnessSession from "../assets/homepage/fitness-session.svg";
import ambulanceCall from "../assets/homepage/ambulance-call.svg";
import mentalWellness from "../assets/homepage/mental-wellness.svg";
import CommonForm from "../components/common/CommonForm";

const plansData = {
  "swasthya-suraksha": {
    name: "Swasthya Suraksha",
    discountPrice: "999/-",
    totalPrice: "1,999",
    title: "Your first step to comprehensive health and financial protection",
    image: SwasthyaSuraksha,
    imageTab: SwasthyaSurakshaTab,
    imageMobile: SwasthyaSurakshaMobile,
    benefits: [
      {
        subTitle: "Doctor consultation",
        image: doctorConsulatation,
        keypoints: [
          {
            type: "Benefits",
            description:
              "Access to qualified MBBS/MD doctors, 15+ languages, 24/7, all year round",
          },
          {
            type: "Usage",
            description: "Consult with doctors via audio or video calls",
          },
          {
            type: "Coverage",
            description: "Available for a family of 4",
          },
        ],
      },
      {
        subTitle: "Hospital cash benefit",
        image: cashBenefit,
        keypoints: [
          {
            type: "Benefits",
            description:
              "₹500/day for regular stays, ₹1000/day for ICU, up to 30 days each per year",
          },
          {
            type: "Usage",
            description: "For hospitalisation over 24 hours",
          },
          {
            type: "Coverage",
            description: "Available for Primary member",
          },
        ],
      },
      {
        subTitle: "Personal accidental insurance",
        image: accidentalInsurance,
        keypoints: [
          {
            type: "Benefits",
            description:
              "₹1,00,000/- accidental injuries/death, ₹5000/- funeral expenses",
          },
          {
            type: "Usage",
            description: "Financial support for accidents and funeral costs",
          },
          {
            type: "Coverage",
            description: "Available for primary member",
          },
        ],
      },
    ],
  },
  healthyway: {
    name: "Healthyway",
    discountPrice: "1,999/-",
    totalPrice: "3,999",
    title: "Supplement your health insurance with diagnostic care",
    image: healthyWay,
    imageTab: healthyWayTab,
    imageMobile: healthyWayMobile,
    benefits: [
      {
        subTitle: "Doctor consultation",
        image: doctorConsulatation,
        keypoints: [
          {
            type: "Benefits",
            description:
              "Access to qualified MBBS/MD doctors, 15+ languages, 24/7, all year round",
          },
          {
            type: "Usage",
            description: "Consult with doctors via audio or video calls",
          },
          {
            type: "Coverage",
            description: "Available for a family of 4",
          },
        ],
      },
      {
        subTitle: "Nutrition consultation",
        image: nutritionistConsulatation,
        keypoints: [
          {
            type: "Benefits",
            description: "Access to advice from certified nutritionists",
          },
          {
            type: "Usage",
            description:
              "4 consultations with customized diet plans and regular follow-ups",
          },
          {
            type: "Coverage",
            description: "Available for  primary and secondary members",
          },
        ],
      },
      {
        subTitle: "Diagnostic test",
        image: diagnosticTest,
        keypoints: [
          {
            type: "Benefits",
            description:
              "64 tests with free home collection and results in 24 hours",
          },
          {
            type: "Usage",
            description: "Voucher valid for one test per year",
          },
          {
            type: "Coverage",
            description: "64 health parameters",
          },
        ],
      },
    ],
  },
  healthedge: {
    name: "HealthEdge",
    discountPrice: "29,999/-",
    totalPrice: "5,9999",
    title: "Holistic health coverage with extensive support services",
    image: healthEdge,
    imageTab: healthEdgeTab,
    imageMobile: healthEdgeMobile,
    benefits: [
      {
        subTitle: "Doctor consultation",
        image: doctorConsulatation,
        keypoints: [
          {
            type: "Benefits",
            description:
              "Access to qualified MBBS/MD doctors, 15+ languages, 24/7, all year round",
          },
          {
            type: "Usage",
            description: "Consult with doctors via audio or video calls",
          },
          {
            type: "Coverage",
            description: "Available for a family of 4",
          },
        ],
      },
      {
        subTitle: "Nutritionist consultation",
        image: nutritionistConsulatation,
        keypoints: [
          {
            type: "Benefits",
            description: "Access to advice from certified nutritionists",
          },
          {
            type: "Usage",
            description:
              "4 consultations with customized diet plans and regular follow-ups",
          },
          {
            type: "Coverage",
            description: "Available for primary and secondary members",
          },
        ],
      },
      {
        subTitle: "Diagnostic test",
        image: diagnosticTest,
        keypoints: [
          {
            type: "Benefits",
            description:
              "64 tests with free home collection and results in 24 hours",
          },
          {
            type: "Usage",
            description: "Voucher valid for one test per year",
          },
          {
            type: "Coverage",
            description: "64 health parameters",
          },
        ],
      },
      {
        subTitle: "Fitness subscription",
        image: fitnessSession,
        keypoints: [
          {
            type: "Benefits",
            description:
              "Access to 10+ exciting workout forms at 1200+ fitness center",
          },
          {
            type: "Usage",
            description: "12 sessions per month till validity",
          },
          {
            type: "Coverage",
            description: "For primary member",
          },
        ],
      },
      {
        subTitle: "Ambulance on call",
        image: ambulanceCall,
        keypoints: [
          {
            type: "Benefits",
            description: "24/7 access to ambulances within 2 hours",
          },
          {
            type: "Usage",
            description: "available across pan India",
          },
          {
            type: "Coverage",
            description: "For primary member",
          },
        ],
      },
      {
        subTitle: "Mental wellness",
        image: mentalWellness,
        keypoints: [
          {
            type: "Benefits",
            description:
              "Instant access to mental wellness support in 10+ languages",
          },
          {
            type: "Usage",
            description: "One session per quater",
          },
          {
            type: "Coverage",
            description: "For primary member",
          },
        ],
      },
    ],
  },
};

const KnowMorePlan = () => {
  const { plan } = useParams();
  const planData = plansData[plan.toLowerCase()];
  const formRef = useRef(null);

  const handleBookNow = () => {
    setTimeout(() => {
      if (formRef.current) {
        const offset = 120;
        const elementPosition =
          formRef.current.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: elementPosition - offset,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  if (!planData) {
    return <div>Plan not found</div>;
  }

  return (
    <div className="home-wrapper flex flex-col items-center">
      <div className="w-full md:max-w-[1248px] mb-[48px] lg:my-[120px]">
        <div className="bgc-transparent know-more-plan-wrapper lg:p-10 rounded-[40px]">
          <div className="hidden lg:block">
            <div className="grid grid-cols-2 gap-14 mb-20">
              <div className="flex flex-col justify-center px-5">
                <div className="heading1 mb-2">{planData.name}</div>
                <div className="font-[400] leading-9 text-[24px] mb-6">
                  {planData.title}
                </div>
                <div className="flex items-center mb-10">
                  <span className="heading2 pr-2">
                    ₹{planData.discountPrice}
                  </span>
                  <span className="para line-through">
                    {planData.totalPrice}
                  </span>
                </div>
                <button
                  class="my-button h-10 w-[178px]"
                  onClick={() => handleBookNow()}
                >
                  Get a call back
                </button>
              </div>

              <div className="relative">
                <div className="validity-text para w-[185px] font-[400]">
                  Validity 365 days
                </div>
                <img
                  className="rounded-3xl"
                  src={planData.image}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="lg:hidden">
            <div className="grid grid-cols-1 gap-6 md:gap-10 md:mb-20 lg:mb-6">
              <div className="relative">
                <div className="validity-text para font-[400]">
                  Validity 365 days
                </div>
                <img
                  className="hidden md:block lg:hidden xl:hidden 2xl:hidden"
                  src={planData.imageTab}
                  alt=""
                  loading="lazy"
                />
                <img
                  className="md:hidden sm:block"
                  src={planData.imageMobile}
                  alt=""
                  loading="lazy"
                />
              </div>

              <div className="px-5 md:mb-0 mb-10">
                <div className="heading1 mb-3">{planData.name}</div>
                <div className="md:text-[18px] para mb-3">{planData.title}</div>
                <div className="mb-3 flex items-center">
                  <span className="heading2 pr-2">
                    ₹{planData.discountPrice}
                  </span>
                  <span className="para line-through">
                    {planData.totalPrice}
                  </span>
                </div>
                <button
                  class="my-button h-10 w-[178px]"
                  onClick={() => handleBookNow()}
                >
                  Get a call back
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5">
            {planData.benefits.map(({ image, subTitle, keypoints }) => (
              <div className="know-more-plan-card rounded-lg text-center p-5">
                <div className="flex justify-center mb-6">
                  <img src={image} alt="" className="h-20 w-20" />
                </div>
                <div>
                  <h2 className="heading3 mb-2 know-more-subTitle">
                    {subTitle}
                  </h2>
                  <p className="para leading-6 font-[400]">
                    {keypoints.map((e) => {
                      return (
                        <ul className="ml-2 pl-2 mb-2">
                          <li className="list-disc text-left">
                            <span className="font-[500] para">{e.type}: </span>
                            {e.description}
                          </li>
                        </ul>
                      );
                    })}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          ref={formRef}
          className="know-more-plan-wrapper plan-wrapper py-10 px-5 md:p-10 md:rounded-[40px] mt-[48px] md:mt-[120px]"
        >
          <p className="heading2 text-center md:text-left mb-2">
            Get a call back
          </p>
          <p className="para text-center md:text-left">
            Know more about {planData.name} plan?
          </p>
          <CommonForm subject={`ourplan - ${planData.name}`} title="Submit" />
        </div>
      </div>
    </div>
  );
};

export default KnowMorePlan;
