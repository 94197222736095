import React, { useState } from "react";
import header from "../assets/contact_us/contact-header-img.png";
import phone from "../assets/contact_us/carbon_phone-filled.svg";
import mail from "../assets/contact_us/fluent_mail-20-filled.svg";
import location from "../assets/contact_us/carbon_location-filled.svg";
import MobileNumberInput from "../components/form/MobileNumberInput";
import EmailInput from "../components/form/EmailInput";
import Label from "../components/form/Label";
import axios from "axios";
import {
  validateEmail,
  validateMobileNumber,
  validateName,
} from "../services/constant";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    subject: "get a call back",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFormValidation = () => {
    if (!validateName(user.name) || user.name.length < 3) {
      return false;
    } else if (!user.email || !validateEmail(user.email)) {
      return false;
    } else if (!validateMobileNumber(user?.mobile)) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (handleFormValidation()) {
      axios
        .post("https://aiqahealth.doctoroncall.org/api/lead", user)
        .then((response) => {
          if (response?.status === 200) {
            Swal.fire({
              title: "",
              text: "Request submitted successfully! Our team will be in touch shortly.",
              icon: "success",
              confirmButtonColor: "#db2228",
            });
            setUser({
              name: "",
              email: "",
              mobile: "",
              message: "",
              subject: "get a call back",
            });
            setError({});
          }
        })
        .catch(function (error) {
          Swal.fire({
            title: "",
            text: "Error in making the request. Please try again.",
            icon: "error",
            confirmButtonColor: "#db2228",
          });
          setError(error?.response?.data?.errors);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setUser({ ...user, [name]: value });
    setError({ ...error, [name]: "" });
  };

  return (
    <div className="home-wrapper flex justify-center">
      <div className="w-full md:max-w-[1248px] my-6 md:my-[120px]">
        <div className="flex flex-wrap justify-center">
          <div className="lg:rounded-3xl flex overflow-hidden flex-col lg:flex-row">
            <div className="w-full lg:w-[40%]">
              <div className="w-full md:h-[445px] bgc-image p-10">
                <img
                  className="h-[170px] mx-auto mb-6"
                  src={header}
                  alt=""
                  loading="lazy"
                />
                <div className="flex flex-col">
                  <div className="flex items-center gap-3 mb-3">
                    <span
                      className="p-2 rounded-full"
                      style={{
                        background:
                          "linear-gradient(97.57deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 100%",
                      }}
                    >
                      <img src={phone} alt="" className="h-6 w-6" />
                    </span>
                    <a href="tel:9105556666" className="para">
                      +91 6262 306 306
                    </a>
                  </div>
                  <div className="flex items-center gap-3 mb-3">
                    <span
                      className="p-2 rounded-full"
                      style={{
                        background:
                          "linear-gradient(97.57deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 100%",
                      }}
                    >
                      <img src={mail} alt="" className="h-6 w-6" />
                    </span>
                    <a href="mailto: care@aiqahealth.com" className="para">
                      care@aiqahealth.com
                    </a>
                  </div>
                  <div className="flex items-center gap-3">
                    <span
                      className="p-2 rounded-full"
                      style={{
                        background:
                          "linear-gradient(97.57deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 100%",
                      }}
                    >
                      <img
                        src={location}
                        alt=""
                        className="w-16 h-6 md:w-8 md:h-6 lg:w-12 lg:h-6"
                      />
                    </span>
                    <span className="para">
                      Hospido private limited, 5th floor, SAS tower, sector 38,
                      Gurugram, Haryana 122001
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              animateIn="bounceInRight"
              className="animation-banner-bg px-5 py-10 md:p-10 relative w-full lg:w-[60%] bgc-transparent"
            >
              <form onChange={onChangeHandler}>
                <div className="flex flex-col gap-2 mb-5">
                  <Label label={"Full name"} required={true} />
                  <input
                    name="name"
                    value={user.name}
                    onChange={onChangeHandler}
                    placeholder="Enter your name"
                    className="bg-white outline-none h-12 rounded-xl p-2"
                    maxLength={32}
                  />
                  {error?.name?.[0] && (
                    <span className="para text-primary-500 pl-2">
                      {error?.mobile?.[0]}
                    </span>
                  )}
                </div>
                <div className="flex flex-col gap-2 mb-5">
                  <div className="flex flex-wrap justify-between gap-5">
                    <div className="md:w-[48%] w-full">
                      <MobileNumberInput
                        name="mobile"
                        required
                        label="Phone number"
                        placeholder="Enter your phone number"
                        value={user.mobile}
                        onChange={onChangeHandler}
                      />
                      {error?.mobile?.[0] && (
                        <span className="para text-primary-500 pl-2">
                          {error?.mobile?.[0]}
                        </span>
                      )}
                    </div>
                    <div className="md:w-[48%] w-full">
                      <EmailInput
                        name="email"
                        label={"Email"}
                        required
                        placeholder="Enter your email"
                        value={user.email}
                        onChange={onChangeHandler}
                      />
                      {error?.email?.[0] && (
                        <span className="text-[12px] text-primary-500 pl-2">
                          {error?.email?.[0]}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 mb-5">
                  <Label label="Your message" />
                  <textarea
                    name="message"
                    placeholder="Enter your message"
                    className="rounded-lg p-2 outline-none"
                    rows="3"
                    cols="50"
                    value={user.message}
                    onChange={onChangeHandler}
                  />
                </div>
              </form>
              <div className="text-center">
                <button
                  disabled={!handleFormValidation() || loading}
                  className="my-button w-[137px] h-[40px]"
                  onClick={handleSubmit}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
